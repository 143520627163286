// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// for dev
// export const environment = {
//   production: true,
//   analysisApiUrl: 'http://ec2-13-214-130-56.ap-southeast-1.compute.amazonaws.com',
//   apiUrl: 'http://ec2-13-214-130-56.ap-southeast-1.compute.amazonaws.com',
//   assetAPIUrl: 'http://ec2-13-214-130-56.ap-southeast-1.compute.amazonaws.com',
//   assetUrl: 'http://ec2-13-214-130-56.ap-southeast-1.compute.amazonaws.com',
//   environment: 'staging',
//   stripePublishableKey: 'pk_test_51MVpF6F2akZzXFVbglG1i0Fa6hXxCf3WDWBEPCgjEnxcLSm5SrOcx0DvnQ1dxkM8yjPJ5u0GgIATCikDPN8jCU4800EQ7jqhSe'
// };

// export const environment = {
//   production: true,
//   analysisApiUrl: 'http://13.212.19.102:80/api',
//   apiUrl: 'http://13.212.19.102:80/api',
//   assetAPIUrl: 'http://13.212.19.102:80/api',
//   assetUrl: 'http://13.212.19.102:80/api',
//   environment: 'staging',
//   stripePublishableKey: 'pk_test_51MVpF6F2akZzXFVbglG1i0Fa6hXxCf3WDWBEPCgjEnxcLSm5SrOcx0DvnQ1dxkM8yjPJ5u0GgIATCikDPN8jCU4800EQ7jqhSe'
// };
// export const environment = {
//   production: true,
//   analysisApiUrl: 'http://18.143.164.11:3000',
//   apiUrl: 'http://18.143.164.11:3000',
//   assetAPIUrl: 'http://18.143.164.11:3000',
//   assetUrl: 'http://18.143.164.11:3000',
//   environment: 'staging',
//   stripePublishableKey: 'pk_test_51MVpF6F2akZzXFVbglG1i0Fa6hXxCf3WDWBEPCgjEnxcLSm5SrOcx0DvnQ1dxkM8yjPJ5u0GgIATCikDPN8jCU4800EQ7jqhSe',
//   PRIVACY_RESTRICTIONS_ENABLED: false,
// };

// Production Endpoints
export const environment = {
  production: true,
  analysisApiUrl: 'https://app.rescalers.com/api',
  apiUrl: 'https://app.rescalers.com/api',
  assetAPIUrl: 'https://app.rescalers.com/api',
  assetUrl: 'https://app.rescalers.com/api',
  environment: 'staging',
  stripePublishableKey: 'pk_test_51MVpF6F2akZzXFVbglG1i0Fa6hXxCf3WDWBEPCgjEnxcLSm5SrOcx0DvnQ1dxkM8yjPJ5u0GgIATCikDPN8jCU4800EQ7jqhSe',
  PRIVACY_RESTRICTIONS_ENABLED: false,
};


// export const environment = {
//   production: true,
//   analysisApiUrl: 'http://localhost:3000',
//   apiUrl: 'http://localhost:3000',
//   assetAPIUrl: 'http://localhost:3000',
//   assetUrl: 'http://localhost:3000',
//   environment: 'staging',
//   stripePublishableKey: 'pk_test_51MVpF6F2akZzXFVbglG1i0Fa6hXxCf3WDWBEPCgjEnxcLSm5SrOcx0DvnQ1dxkM8yjPJ5u0GgIATCikDPN8jCU4800EQ7jqhSe',
//   PRIVACY_RESTRICTIONS_ENABLED: true,
// };

// analysisApiUrl: 'https://host3.rescalelab.com',
// apiUrl: 'https://host3.rescalelab.com',
// assetAPIUrl: 'https://host3.rescalelab.com',
// assetUrl: 'https://host3.rescalelab.com',

// https://host3.rescalelab.com
/* analysisApiUrl: 'http://localhost:3000',
apiUrl: 'http://localhost:3000',
assetAPIUrl: 'http://localhost:3000',
assetUrl: 'http://localhost:3000', */
// export const environment = {
//   production: true,
//   analysisApiUrl: 'https://host.eatlaunchpad.com',
//   apiUrl: 'https://host.eatlaunchpad.com',
//   assetAPIUrl: 'https://host.eatlaunchpad.com',
//   assetUrl: 'https://host.eatlaunchpad.com',
//   environment: 'host.'
// };
// 'https://stagserver.eatlaunchpad.com:8440',

// for Ken http://localhost:3000
// export const environment = {
//   production: true,
//   apiUrl: 'https://server.eatlaunch.com',
//   assetUrl: 'https://server.eatlaunch.com',
//   assetAPIUrl: 'https://server.eatlaunch.com',
//   environment: 'dev'
// };

// live
/*  export const environment = {
   production: true,
   apiUrl: 'https://server.eatlaunchpad.com:8440',
   assetUrl: 'https://server.eatlaunchpad.com:8440',
   assetAPIUrl: 'https://server.eatlaunchpad.com:8440',
   environment: 'dev'
 }; */

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
