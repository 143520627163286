import {
    Component,
    OnInit,
    Input,
    Output,
    AfterViewInit,
    ViewEncapsulation,
    OnChanges,
    SimpleChanges,
    ElementRef,
    ViewChild,
    EventEmitter,
} from '@angular/core';
import {
    ConfigService,
    DataService,
    EatToasterService,
    EmailService,
    OrganizationService,
    UserService,
    UtilityService,
    InstitutionService,
    WidgetService,
    WidgetSetService,
} from '@common/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Msg } from '@modules/_shared/models';
import { User } from '@common/models';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
@Component({
    selector: 'lab-modal-organization-list',
    templateUrl: './modal-organization-list.component.html',
    styleUrls: ['./modal-organization-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ModalOrganizationListComponent implements OnInit, AfterViewInit, OnChanges {
    // WIP Don't need currentOrg???? just need batchId? u sure????
    isPrivacyRestrictionsEnabled = environment.PRIVACY_RESTRICTIONS_ENABLED
    _currentOrg: any;
    _params: any;
    loading: boolean = true;
    @Input() set params(object) {
        if (object) {
            this._params = object;
            if (this._params.batchId) {
                this.initialOrg(this._params.batchId, this._params.type);
            }
        }
    }

    @Input() set currentOrg(object) {
        if (object) {
            this._currentOrg = object;
            /*   if (this.type === 'batch') {
                  this.initalUser(this.batchId, "default");
              } else {
                  this.getStaffList();
              } */
        }
    }
    @Input() invitedType;

    currentUser: User;

    // mentor or startup
    organizations: Array<any> = [];
    displayOrganizations: Array<any> = [];
    allOrgs: Array<any> = [];

    searchText;
    inviteByEmail = false;

    userEmails: Array<any> = [];
    invByEmailForm: FormGroup;
    emailTitle: string;
    invMessage = 'E-mail and notification sent to';
    msgType = '';

    emailRegex = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    valid = true;

    typingTimer;

    displaytext = '';

    toastRef;
    //WIP
    projects;
    experts;
    batchId;

    @ViewChild('filterList') filterList: ElementRef;

    @Output() onSelected = new EventEmitter<any>();
    @Output() onInvited = new EventEmitter<any>();
    isCreateOrganization = false;
    isCreateMentor = false;
    constructor(
        private userSrv: UserService,
        private toastr: ToastrService,
        private dataSrv: DataService,
        private emailSrv: EmailService,
        private toasterSrv: EatToasterService,
        private utility: UtilityService,
        public globals: ConfigService,
        private orgSrv: OrganizationService,
        private institutionSrv: InstitutionService,
        private widgetSrv: WidgetService,
        private router: Router,
        private widgetSetSrv: WidgetSetService
    ) {
        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges) { }

    ngAfterViewInit() { }

    fetchAndInvite(orgId: string) {
        this.initialOrg(this._params.batchId, this._params.type, orgId);
    }

    initialOrg(batchId, type, orgId?: string) {
        if (type === 'mentor') {
            this.institutionSrv
                .getOrgByType(batchId, 'professionalservice')
                .then((res) => {
                    if (res['result'] == 'successful') {
                        if (res['data'].length > 0) {
                            this.organizations = res['data'].filter(
                                (org) => org.status !== 'joined'
                            );

                            this.displayOrganizations = this.organizations;
                            if (orgId) {
                                const inviteOrg = this.organizations.find(x => x.organizationId === orgId)
                                if (inviteOrg) {
                                    this.invNoti(inviteOrg);
                                    // this.searchText = inviteOrg.name;

                                }
                            }
                        }
                    } else {
                        console.error('failed', res);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
        if (type === 'startup') {
            this.institutionSrv
                .getOrgByType(batchId, 'project')
                .then((res) => {
                    if (res['result'] == 'successful') {
                        if (res['data'].length > 0) {
                            this.organizations = res['data'].filter(
                                (org) => org.status !== 'joined'
                            );

                            this.displayOrganizations = this.organizations;
                            if (orgId) {
                                const inviteOrg = this.organizations.find(x => x.organizationId === orgId)
                                if (inviteOrg) {
                                    this.invNoti(inviteOrg);
                                    // this.searchText = inviteOrg.name;

                                }
                            }
                        }
                    } else {
                        console.error('failed', res);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }

    onSearchChange() {
        clearTimeout(this.typingTimer);
    }

    doneTypingStartup(value) {
        this.orgSrv
            .searchStartup(this.batchId, '715189cc-da99-11e9-b68a-bb4d51af784e', value)
            .subscribe((res) => {
                if (res['result'] === 'successful') {
                    //   this.users = res['data'];
                    this.inviteByEmail = false;
                } else {
                    //     this.users = [];
                    this.displaytext = `Sorry, we cannot find ${this.searchText} in our members list. Would you want to try to send an email to tell
          your friend`;
                    this.inviteByEmail = true;
                }
            });
    }

    doneTypingMentor(value) {
        this.orgSrv
            .searchMentor(this.batchId, '6695c71e-da99-11e9-b68a-bb4d51af784e', value)
            .subscribe((res) => {
                if (res['result'] === 'successful') {
                    this.inviteByEmail = false;
                } else {
                    //    this.users = [];
                    this.displaytext = `Sorry, we cannot find ${this.searchText} in our members list. Would you want to try to send an email to tell
                your friend`;
                    this.inviteByEmail = true;
                }
            });
    }

    // doneTyping(value) {
    //     this.userSrv.searchUsers(value, this._currentOrg.id).subscribe((res) => {

    //         if (res['result'] === 'successful') {

    //             this.inviteByEmail = false;
    //         } else {
    //             this.displaytext = `Sorry, we cannot find ${this.searchText} in our members list. Would you want to try to send an email to tell
    //             your friend`;
    //             this.inviteByEmail = true;
    //         }
    //     });
    // }

    onClose() {
        this.searchText = '';
    }

    onKeydown($event) { }

    onFilterSelect(event) {
        const { childNodes } = this.filterList.nativeElement;
        const list = [...childNodes];
        list.forEach((item) => {
            if (
                item.dataset.option === event.target.dataset.option &&
                item.classList.contains('selected')
            )
                return;
            item.classList.remove('selected');
            if (item.dataset.option === event.target.dataset.option)
                return item.classList.add('selected');
        });
        // Store filter type for search using

        this.displayOrganizations = this.organizations;
        if (event.target.dataset.option !== 'all') {
            this.displayOrganizations = this.organizations.filter((organization) => {
                if (event.target.dataset.option == 'invite') {
                    return this.utility.IsNullOrEmpty(organization.status);
                } else {
                    return organization.status === event.target.dataset.option;
                }
            });
        }
    }

    isInArray(val, arr) {
        return arr.indexOf(val) > -1;
    }

    // inform organization owner by email
    invNoti(org) {
        const _noti = new Msg();
        const currentUnixTime = new Date();

        _noti.batchId = this._params.batchId;
        _noti.requesterUid = this.currentUser.id;
        _noti.senderUid = this.currentUser.id;
        _noti.requesterOrgId = this._currentOrg.organizationId;
        _noti.requesterOrgTypeId = this._currentOrg.organizationTypeId;
        _noti.invitedUid = org.owner;
        _noti.receiverId = org.owner;
        _noti.invitedOrgId = org.organizationId;
        _noti.receiverName = org.name;
        _noti.organizationTypeId = org.organizationTypeId;
        _noti.requestDate = currentUnixTime.getTime().toString();
        _noti.title = 'Invitation from ' + this._currentOrg.name;
        _noti.content =
            `Hello ${_noti.receiverName},` +
            ` I am ${this.currentUser.fullname} & ` +
            `I would like to invite you to join `;
        // WIP
        // `${this.currentOrg.name} ${this.currentBatch.name} on the platform.`;
        _noti.invitationType = '2';
        _noti.createdAt = currentUnixTime.getTime().toString();
        _noti.createdBy = this.currentUser.id;
        _noti.updatedAt = currentUnixTime.getTime().toString();
        _noti.updatedBy = this.currentUser.id;
        //mentor type
        //matchedOrgType 1: startup 4:mentor
        _noti.matchedOrgType = '4';
        if (this._params.type === 'startup') {
            _noti.matchedOrgType = '1';
        }

        if (this.utility.IsNullOrEmpty(org.status)) {
            this.addOrgBatchRelationship(
                _noti.requesterOrgId,
                _noti.requesterOrgTypeId,
                _noti.invitedOrgId,
                _noti.senderUid,
                _noti.batchId,
                _noti.receiverId,
                _noti.organizationTypeId,
                _noti.matchedOrgType
            ).subscribe(
                (result) => {
                    if (result['result'] === 'successful') {
                        let _org = this.organizations.find((org) => (org.id === _noti.invitedOrgId));
                        _org.status = 'inviting';
                        this.displayOrganizations = this.organizations;
                        this.isCreateOrganization = false;
                        this.isCreateMentor = false;
                        // create notfication
                        this.dataSrv.createNewInv(_noti).subscribe(
                            (data) => {
                                if (data['result'] == 'successful') {
                                    this.showActionMsg('Invitation', 'Successful', "");

                                    // it only work when status is invite
                                    // it mean that this organization have invited.
                                    // if (this.utility.IsNullOrEmpty(org.status)) {
                                    //     this.addOrgBatchRelationship(
                                    //         _noti.requesterOrgId,
                                    //         _noti.requesterOrgTypeId,
                                    //         _noti.invitedOrgId,
                                    //         _noti.senderUid,
                                    //         _noti.batchId,
                                    //         _noti.receiverId,
                                    //         _noti.organizationTypeId,
                                    //         _noti.matchedOrgType
                                    //     );
                                    // }
                                    const link = window.location.origin + '/login?time=' + new Date().getTime();
                                    this.emailSrv
                                        .sendBatchInviteEmail(
                                            _noti.title,
                                            org.email,
                                            link,
                                            this.currentUser.id,
                                            this.currentUser.fullname,
                                            this._currentOrg.name
                                        )
                                        .subscribe((value) => {
                                            // this.ShowMsg(
                                            //   `E-mail and notification sent to ${organization.name}.`,
                                            //   0
                                            // );
                                            setTimeout(() => {
                                                this.invMessage = '';
                                                this.msgType = '';
                                            }, 2000);
                                        });
                                    // this.router.navigate(['./w/batch/profile'],
                                    //     {
                                    //         queryParams: {
                                    //             id: this.currentUser.id,
                                    //             batchId: _noti.batchId,
                                    //             orgId: this._currentOrg?.id,
                                    //             orgType: 'batch',
                                    //         }
                                    //     });
                                    this.onInvited.emit(_noti.batchId);
                                }
                            },
                            (error) => console.log('createNewInv :', error),
                            () => { }
                        );
                    }
                    else if (result['result'] === 'user_exist') {
                        let exist_string = `Below users are already exist. Remove the users then invite.
                        Users: `
                        let inc = 0;
                        for (let user of result['data']) {
                            inc++;
                            exist_string += user.user_name;
                            if (result['data'].length !== inc) {
                                exist_string += " ,"
                            }
                        }
                        this.showActionMsg("User Exist", exist_string, "red-title");
                    }
                },
                (error) => console.log(error),
                () => { }
            );
        }


    }

    addOrgBatchRelationship(
        requesterOrgId,
        requesterOrgTypeId,
        invitedOrgId,
        uid,
        batchId,
        receiverUid,
        organizationTypeId,
        _matchedOrgType
    ) {
        return this.institutionSrv
            .addOrgRelationship(
                '',
                requesterOrgId,
                requesterOrgTypeId,
                invitedOrgId,
                organizationTypeId,
                uid,
                batchId,
                _matchedOrgType,
                'inviting',
                '0',
                _matchedOrgType
            )

    }

    showActionMsg(title, msg, color) {
        this.toastRef = this.toastr.show(msg, title, {
            tapToDismiss: true,
            toastClass: 'toastpayment ',
            titleClass: color || 'green-title',
            closeButton: false,
            timeOut: 5000,
        });
    }

    onImgError(event) {
        event.target.src = this.globals.defaultUserLogo;
    }

    //show mentor or mentee
    onSelect(id) {
        this.onSelected.emit(id);
    }

    onCreateOrganization(event: any) {
        this.isCreateOrganization = true;
    }
    onCreateMentor(event:any){
        this.isCreateMentor = true;
    }

    setIsCreateOrganization(event: boolean) {
        this.isCreateOrganization = event;
        this.isCreateMentor = event;
    }
}
