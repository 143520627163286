import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { User } from '@common/models';

@Component({
    selector: 'batch-org-card',
    templateUrl: './batch-org-card.component.html',
    styleUrls: ['batch-org-card.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OrgCardComponent implements OnInit {
    @Input() name: string;
    @Input() id: any;
    @Input() relationshipId: any;
    @Input() type: string;
    @Input() contacts: any;
    @Input() avatar: string;
    @Input() isDeleted: boolean = false;
    @Input() selected: boolean = false;
    @Input() fromOrg;
    @Input() currentTeamStatus;
    @Input() batchName: string;
    @Input() invitationStatus: boolean;
    @Input() associationCount: any;
    @Input() consultantCount: any;
    @Input() roleName: string;
    @Input() batchType: string;
    @Input() status: string;
    @Input() participantCount: string;
    @Output() remove = new EventEmitter<any>();
    @Output() onSelected = new EventEmitter<string>();
    @Output() click = new EventEmitter<string>();
    @Output() swap = new EventEmitter<any>();

    //new
    @Input() isSliderCard: boolean = false;

    @Input() participantsCount: any;
    @Input() projectsCount: any;

    /////////////

    @Input() project_count: any;
    @Input() participants_count: any;

    @Input() userCount: any;
    @Input() consultant_count: any;

    @Input() role: string = '';

    constructor() {
        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    }

    ngOnInit(): void { }

    onSlectedCard() {
        this.onSelected.emit(this.id);
    }

    currentUser;

    onRemove(event: any) {
        event.stopPropagation();

        // if (this.type) {
        //     this.remove.emit(this.id);
        // } else {
        //     this.remove.emit(this.relationshipId);
        // }
        if (this.type === 'user') return this.remove.emit();
        return this.remove.emit();
    }

    onClickContacts(event: any) {
        event.stopPropagation();
    }

    getStudentState() {
        let studentState = '';
        if (this.type === 'Students' || this.batchType == '1') {
            studentState = !this.invitationStatus
                ? 'pending'
                : (this.batchType == '1' && !this.consultantCount) ||
                    (!this.batchName && this.batchType == '0')
                    ? 'notassign'
                    : 'batch';

            if(this.isSliderCard) {
                studentState = !this.invitationStatus  ? 'pending' : !this.consultantCount ? 'notassign' : ''
            }
        }
        if (this.type === 'startup') {
            studentState = !this.consultantCount ? 'notassign' : 'batch';
        }
        if (this.type === 'mentor') {
            studentState =
                this.status === 'inviting'
                    ? 'pending'
                    : (this.batchType == '0' && !this.associationCount) ||
                      (this.batchType == '1' && !this.participantCount)
                    ? 'notassign'
                    : 'batch';

            if(this.isSliderCard && this.batchType === "0") {
                studentState =  this.status === 'inviting' ? 'pending'
                : !this.projectsCount ? 'notassign' : ''
            }

            if(this.isSliderCard && this.batchType === "1") {
                studentState =  this.status === 'inviting' ? 'pending'
                : !this.participantsCount ? 'notassign' : ''
            }

        }

        if (this.type === 'user' && !this.invitationStatus) {
            studentState = 'pending';
        }
        return studentState;
    }

    onSwap(event: any) {
        event.stopPropagation();
        this.swap.emit();
    }
}
